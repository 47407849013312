import * as yup from 'yup';
import { RegExEnum } from '@/app/utils/constants';

export function registerValidators() {
  yup.addMethod(yup.string, 'spacesValidator', function (errorMessage = 'Field cannot be empty') {
    return this.test(`spaces-validator`, errorMessage, function (value, ctx) {
      const { path, createError } = this;
      const inputValue = ctx.originalValue;
      const isSpaces = RegExEnum.SPACE.test(inputValue);
      if (isSpaces) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  });
  yup.addMethod(
    yup.string,
    'strongEmailValidator',
    function (errorMessage = 'Please enter a valid email address.') {
      return this.matches(RegExEnum.EMAIL, errorMessage);
    },
  );
}
