import { FC } from 'react';
import { toast as t, ToastContainer } from 'react-toastify';
import { ToastTypeEnum } from '@/app/utils/constants';
import { BsXLg } from 'react-icons/bs';
import { ByErrorFilled, BySuccessFilled } from '@/app/components/icons/CustomIconPack';

type Props = {
  header: string;
  body?: string;
  type?: ToastTypeEnum;
  toastId?: string;
};
/**
 * Toast UI notifications component for user interaction
 */
export const toast = ({ header, body, type = ToastTypeEnum.DANGER, toastId }: Props) => {
  return t(
    ({ closeToast }) => {
      const iconType = () => {
        switch (type) {
          case ToastTypeEnum.DANGER:
            return <ByErrorFilled size={24} />;
          case ToastTypeEnum.SUCCESS:
            return <BySuccessFilled size={24} />;
        }
      };

      return (
        <div>
          <div className={'Toastify__toast-header'}>
            <div className={'d-flex align-items-start'}>
              <span className={`Toastify__toast-icon-wrapper ${type}`}>{iconType()}</span>
              <div className="d-flex flex-column ms-2">
                <p className={'mb-0 fw-bold'}>{header}</p>
                {body && (
                  <div className={'Toastify__toast-body-wrapper'}>
                    <p className={'b-5 mb-2 mt-2'}>{body}</p>
                  </div>
                )}
              </div>
            </div>
            <span className={`Toastify__toast-btn-close-wrapper d-flex p-2`} onClick={closeToast}>
              <BsXLg />
            </span>
          </div>
        </div>
      );
    },
    { toastId: toastId || undefined },
  );
};

export const ToastProvider: FC = () => {
  return (
    <ToastContainer
      autoClose={4000}
      draggable={false}
      hideProgressBar={true}
      closeOnClick={false}
      icon={false}
      closeButton={false}
      position={'bottom-right'}
    />
  );
};
